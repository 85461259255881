<template>
  <div class="leaderboard pad-1 flex-1">
    <TournamentToggler/>
    <SocialShare/>
    <div class="leaderboard-body-wrapper the-box">
      <div class="box-label pattern-bg">
        {{ $store.state.globalContent.trLeaderboard }} - {{ activeTournamentObject.title }}
      </div>
      <div class="lb-placeholder pad-1 content">
        <div v-html="$store.state.globalContent.trPreLeaderboard"></div>
        <router-link style="font-size: 1.25em; font-weight: 600;" :to="{ path:`/${$store.state.currentGeo}/contents/prizes` }">
          {{ $store.state.globalContent.trPrizes }}
        </router-link>
      </div>
      <div v-if="false" class="leaderboard-body pad-1">
        <div class="button-group">
          <button class="button button--lb active">All Time</button>
          <button class="button button--lb">Current Stage</button>
        </div>
        <div class="user-position-row me">
          <div class="user-pos">12.</div>
          <div class="user-name">
            <UserIcon class="user-icon"/>
            {{ this.$store.state.user.email.split('@')[0] }}
          </div>
          <div class="user-points">80 {{ $store.state.globalContent.trPoints }}</div>
        </div>
        <div class="leaderboard-table">
          <div v-for="n in 1" :key="n" class="user-position-row">
            <div class="user-pos">{{ n }}.</div>
            <div class="user-name">
              <UserIcon class="user-icon"/>
              bar********m
            </div>
            <div class="user-points">80 {{ $store.state.globalContent.trPoints }}</div>
          </div>
          <div class="leaderboard-count">Showing 20 out of 2454</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialShare from '@/components/SocialShare.vue';
import TournamentToggler from '@/components/TournamentToggler.vue';
import UserIcon from '@/assets/images/user.svg';

export default {
  name: 'Leaderboard',
  components: {
    SocialShare,
    TournamentToggler,
    UserIcon,
  },
  methods: {
    checkForActiveTournament() {
      if (this.$store.state.activeTournament !== null) return;
      this.$router.push({
        name: 'Selections',
        params: {
          tournament: this.$store.state.globalContent.activeTournaments[0].slug,
        },
      });
    },
  },
  computed: {
    activeTournamentObject() {
      return this.$store.state.globalContent.activeTournaments.find((tournament) => tournament.slug === this.$store.state.activeTournament);
    },
  },
  created() {
    this.checkForActiveTournament();
  },
};
</script>

<style lang="scss">
  .leaderboard {
    z-index: 1;
  }
  .user-position-row {
    display: flex;
    align-items: center;
    background: $main;
    padding: .25rem 1rem;
    border-radius: 5px;
    margin: .25rem 0;
    font-size: 0.85em;
    &.me {
      margin: 1rem 0;
    }
  }

  .user-name {
    display: flex;
    align-items: center;
    margin-left: .5rem;
    padding-right: 1rem;
  }

  .user-icon {
    height: 16px;
    margin-right: .5rem;
  }
  .user-pos {
    width: 30px;
  }

  .user-points {
    margin-left: auto;
  }
  .leaderboard-table {
    background: $main;
    border-radius: 5px;
  }
  .leaderboard-count {
    padding: .5rem 1rem;
    text-align: center;
    font-weight: 600;
  }
  .button--lb,
  .leaderboard-count {
    font-size: 0.85em;
  }
  .lb-placeholder {
    font-size: 0.85em;
  }
</style>
