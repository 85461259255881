<template>
  <div class="social-share the-box pad-1">
    <div class="social-share-content">
      {{ $store.state.globalContent.trShareInvite }}
    </div>
    <div class="share-button">
      <button @click="share" class="button button--share button--small has-icons">
        {{ $store.state.globalContent.trShare }}
        <ShareIcon class="button-icon share-icon"/>
      </button>
      <input ref="share-input" class="hidden-share-input" type="text" :value="`${this.shareDomain}/${this.$route.params.geo}/login/?shareid=${this.$store.state.user.id}`">
    </div>
    <transition name="fade">
      <div v-if="feedback" class="share-feedback">
        {{ feedback }}
      </div>
    </transition>
  </div>
</template>

<script>
import ShareIcon from '@/assets/images/share.svg';

export default {
  name: 'SocialShare',
  components: {
    ShareIcon,
  },
  data() {
    return {
      feedback: '',
    };
  },
  methods: {
    async share() {
      const shareData = {
        title: 'Predictions4U',
        text: 'EURO 2020 & Copa America 2021 Football! Predict for free and you may Win great Prizes!',
        url: `${this.shareDomain}/${this.$route.params.geo}/login/?shareid=${this.$store.state.user.id}`,
      };
      try {
        await navigator.share(shareData);
      } catch {
        this.copyLink();
      }
    },
    copyLink() {
      this.$refs['share-input'].select();
      document.execCommand('copy');
      this.feedback = this.$store.state.globalContent.trLinkCopied;
      setTimeout(() => {
        this.feedback = '';
      }, 3000);
    },
  },
  computed: {
    shareDomain() {
      return process.env.VUE_APP_FRONT_DOMAIN;
    },
  },
};
</script>

<style lang="scss">
  .social-share {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .share-button {
    margin-top: .25rem;
    width: 100%;
    .button {
      width: 100%;
      justify-content: center;
      font-weight: 600;
    }
  }
  .social-share-content {
    font-size: 12px;
  }
  .button-icon {
    height: 16px;
    &.share-icon {
      margin-left: .5rem;
      fill: $dark-teal;
    }
  }
  .share-feedback {
    margin-top: .5rem;
    font-size: 0.85em;
    font-weight: 600;
    border: 1px dashed $dark-teal;
    padding: 0.25rem;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }
</style>
