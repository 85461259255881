<template>
  <div v-if="getActiveTournaments.length > 0" class="tournament-toggler">
    <button @click="toggleTournament(tournament.slug)" class="button button--small"
    :class="{ active: $route.params.tournament === tournament.slug }" v-for="tournament in getActiveTournaments"
    :key="tournament.slug">
    {{ tournament.title }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TournamentToggler',
  computed: {
    ...mapGetters([
      'getActiveTournaments',
    ]),
  },
  methods: {
    toggleTournament(selectedTournament) {
      if (selectedTournament === this.$route.params.tournament) return;
      this.$router.push({ name: 'Selections', params: { tournament: selectedTournament } });
    },
  },
};
</script>

<style lang="scss">
  .tournament-toggler {
    .button:not(:last-child) {
      margin-right: .25rem;
    }
  }
</style>
